<template>
  <div id="complaintDetails">
    <h1 v-if="isLoading">Loading Details...</h1>
    <div
      v-if="msg"
      :class="{
        alert: true,
        'p-1': true,
        'alert-danger': msg && msg.type == 'danger',
        'alert-success': msg && msg.type == 'success',
      }"
    >
      {{ msg ? msg.text : "" }}
    </div>
    <div v-if="comp">
      <b-row>
        <b-col md="5">
          <b-card header="Verifications Details" no-body header-class="card-header-info">
            <ul class="list-info">
              <li><span>Verification ID</span>{{ comp._id }}</li>
              <li>
                <span>Requested Date</span
                >{{ moment(comp.createdAt).format("YYYY-MM-DD @ hh:mm:ss A") }}
              </li>
              <li>
                <span>Responding Date</span
                >{{ moment(comp.responsedAt).format("YYYY-MM-DD @ hh:mm:ss A") }}
              </li>
              <li><span>Current Status</span>{{ comp.status }}</li>
              <li><span>Type</span>{{ comp.type }}</li>
              <li><span>Comments</span>{{ comp.comments }}</li>
              <li><span>Reason</span>{{ comp.resonId ? comp.reasonId.reason : "n/a" }}</li>
            </ul>
          </b-card>
          <b-card header="Logded By" no-body header-class="card-header-info">
            <ul class="list-info">
              <li>
                <span>Username</span
                ><b-link :href="`/profile/${comp.launchedBy.username}`" target="_blank">{{
                  comp.launchedBy.username
                }}</b-link>
              </li>
              <li><span>User Type</span>{{ comp.launchedBy.userType }}</li>
            </ul>
          </b-card>
          <b-card
            header="Responded By"
            v-if="comp.respondedBy"
            no-body
            header-class="card-header-info"
          >
            <ul class="list-info">
              <li><span>Username</span>{{ comp.respondedBy.username }}</li>
              <li><span>User Type</span>{{ comp.respondedBy.userType }}</li>
              <li><span>Comments/Feedback</span>{{ comp.responderComments }}</li>
            </ul>
          </b-card>
        </b-col>
        <b-col md="7">
          <b-card header="Profile" v-if="comp.profileId" no-body header-class="card-header-info">
            <ul class="list-info">
              <li><span>Profile ID</span>{{ comp.profileId._id }}</li>

              <li><span>Profile Name</span>{{ comp.profileId.name }}</li>
              <li><span>Type</span>{{ comp.profileId.profileType }}</li>
              <li>
                <span>Link</span
                ><b-link target="_blank" :href="`/profile/details/${comp.profileId._id}`"
                  >Click here</b-link
                >
              </li>
            </ul>
          </b-card>
          <b-card header="User" v-if="comp.userId" no-body header-class="card-header-info">
            <ul class="list-info">
              <li><span>User ID</span>{{ comp.userId._id }}</li>
              <li>
                <span>Requested Date</span
                >{{ moment(comp.userId.createdAt).format("YYYY-MM-DD @ hh:mm:ss A") }}
              </li>

              <li>
                <span>Profile Username</span
                ><b-link target="_blank" :href="`/profile/${comp.userId.username}`">{{
                  comp.userId.username
                }}</b-link>
              </li>
            </ul>
          </b-card>
          <b-card header="Comment" v-if="comp.commentId" no-body header-class="card-header-info">
            <ul class="list-info">
              <li><span>Comment ID</span>{{ comp.commentId._id }}</li>
              <li>
                <span>Requested Date</span
                >{{ moment(comp.createdAt).format("YYYY-MM-DD @ hh:mm:ss A") }}
              </li>
              <li>
                <span>Responding Date</span
                >{{ moment(comp.responsedAt).format("YYYY-MM-DD @ hh:mm:ss A") }}
              </li>
              <li><span>Comment</span>{{ comp.commentId.comment }}</li>
            </ul>
          </b-card>
          <b-card v-if="comp.status == 'pending'" title="Perform Action on this complaint">
            <b-row>
              <b-col md="12" class="form-group">
                <div
                  v-if="msgAction"
                  :class="{
                    alert: true,
                    'p-1': true,
                    border: true,
                    'alert-success': msgAction && msgAction.type == 'success',
                    'alert-danger': msgAction && msgAction.type == 'danger',
                    'alert-info': msgAction && msgAction.type == 'info',
                  }"
                >
                  {{ msgAction.text }}
                </div>

                <label for="reason">Type your Comments</label>
                <b-form-textarea
                  :disabled="isLoadingAction"
                  v-model="comments"
                  placeholder="please describe the reason of your actions in few words"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col md="6">
                <b-button
                  @click="updateComplaint('accepted')"
                  :disabled="comments.length < 3 || isLoadingAction"
                  block
                  variant="success"
                  >Accept Request</b-button
                >
              </b-col>
              <b-col md="6">
                <b-button
                  @click="updateComplaint('rejected')"
                  block
                  variant="danger"
                  :disabled="comments.length < 3 || isLoadingAction"
                  >Reject Request Request</b-button
                >
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BTable,
  BFormInput,
  BFormTextarea,
  BLink,
  BButton,
} from "bootstrap-vue";
import moment from "moment";
import vSelect from "vue-select";
export default {
  components: {
    BRow,
    BCard,
    BCol,
    BTable,
    BFormInput,
    BLink,
    BButton,
    BCardBody,
    vSelect,
    BFormTextarea,
  },
  data() {
    return {
      comp: null,
      isLoading: false,
      isLoadingAction: false,
      msg: null,
      msgAction: null,
      comments: "",
    };
  },
  methods: {
    moment: function (input) {
      return moment(input);
    },
    async getComplaintDetails() {
      const complaintId = this.$route.params.id;
      this.isLoading = true;
      try {
        const formData = new FormData();
        formData.append("complaintId", complaintId);
        const { data } = await axios.post(`/complaints/showComplaint`, formData);
        this.comp = data.data;
        this.isLoading = false;
      } catch (e) {
        this.msg = {
          type: "danger",
          text: e.response.data ? e.response.data : e.message,
        };
      }
    },
    async updateComplaint(action) {
      this.isLoadingAction = true;
      this.msgAction = {
        type: "info",
        text: "Please wait...",
      };

      const complaintId = this.$route.params.id;
      try {
        const formData = new FormData();
        formData.append("complaintId", complaintId);
        formData.append("status", action);
        formData.append("responderComments", this.comments);
        const { data } = await axios.put(`/complaints/updateComplaint`, formData);
        this.msgAction = {
          type: "success",
          text: "Complaint Updated Successfully",
        };
      } catch (e) {
        this.msgAction = {
          type: "danger",
          text: e.response.data ? e.response.data : e.message,
        };
        this.isLoadingAction = false;
      }
    },
  },
  created: function () {
    this.getComplaintDetails();
  },
};
</script>
